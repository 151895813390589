<template>
  <div class="overall">
    <!-- <div class="main-Title bgff">
      <h2>Tag</h2>
    </div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="filter" size="mini">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="Ids" label-width="30px" class="mb5">
                <el-input v-model="filter.ids"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="16" :lg="18" :xl="18">
              <el-form-item class="mb5">
                <el-button type="primary" @click="searchList(1)">In Browser</el-button>
                <el-button type="primary" icon="el-icon-plus" @click="addClick">Create</el-button>
                <el-button type="danger" @click="batchDelTag">Delete</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <div>
          <el-table
            v-loading="loading"
            border
            stripe
            :data="dataList"
            style="width: 100%;"
            size="mini"
            highlight-current-row
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="Id" align="center" min-width="60" prop="id"> </el-table-column>
            <el-table-column label="Traffic Tag" prop="trafficTag" align="center" min-width="130">
            </el-table-column>
            <el-table-column
              label="Description"
              prop="description"
              align="center"
              min-width="130"
            ></el-table-column>
            <el-table-column
              label="operator"
              prop="createBy"
              align="center"
              min-width="100"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="operation time"
              prop="createTime"
              align="center"
              min-width="130"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="Option" align="center" min-width="100">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="updateTagClick(scope.row)"
                  >Update</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="align-c">
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="page"
            ></pagination>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 模块框 -->
    <el-dialog
      :title="dialogType === 'new' ? 'Add' : 'Update'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="500px"
    >
      <el-form label-position="right" label-width="100px" :model="tagInfo">
        <el-form-item label="id" v-if="dialogType !== 'new'">
          <el-input v-model="tagInfo.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="Traffic Tag:">
          <el-input v-model="tagInfo.trafficTag"></el-input>
        </el-form-item>
        <el-form-item label="Description:">
          <el-input v-model="tagInfo.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitClick()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import Pagination from '@/components/pagination';
  import { getTagList, addTag, updateTag, deleteTag } from 'api/affiliate/tag';
  export default {
    components: {
      Pagination,
    },
    data() {
      return {
        filter: {
          ids: '',
        },
        dataList: [],
        page: 1,
        pageSize: 10,
        total: 0,
        loading: false,
        dialogVisible: false,
        tagInfo: {
          trafficTag: '',
          description: '',
        },
        dialogType: 'new',
        delIds: [],
      };
    },
    methods: {
      searchList(page) {
        this.loading = true;
        if (page) {
          this.page = page;
        }
        let param = {
          ...this.filter,
          page: this.page,
          pageSize: this.pageSize,
        };
        getTagList(param).then((response) => {
          if (response.code === 200) {
            this.loading = false;
            this.dataList = response.result;
            this.total = response.total;
          } else {
            this.loading = false;
            this.$message.error(response.message);
          }
        });
      },
      addClick() {
        this.tagInfo = {};
        this.dialogType = 'new';
        this.dialogVisible = true;
      },
      updateTagClick(row) {
        this.tagInfo = {
          id: row.id,
          trafficTag: row.trafficTag,
          description: row.description,
        };
        this.dialogType = 'edit';
        this.dialogVisible = true;
      },
      batchDelTag() {
        const ids = [...this.delIds];
        if (ids.length === 0) {
          this.$message.warning('请先选择要删除的数据');
          return;
        }
        deleteTag(ids).then((response) => {
          if (response.code === 200) {
            this.$message.success('Delete Success');
            this.searchList();
            return;
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      submitClick() {
        if (this.dialogType === 'new') {
          addTag({ ...this.tagInfo }).then((response) => {
            if (response.code == 200) {
              this.$message({
                message: 'Add Success',
                type: 'success',
              });
              this.searchList(1);
              this.dialogVisible = false;
            } else {
              this.$message.error('Add Error:' + response.message);
            }
          });
        } else if (this.dialogType === 'edit') {
          updateTag({ ...this.tagInfo }).then((response) => {
            if (response.code === 200) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.searchList();
              this.dialogVisible = false;
            } else {
              this.$message.error('Update Error:' + response.messsage);
            }
          });
        }
      },
      handleSelectionChange(val) {
        this.delIds = val?.map((item) => item.id);
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.page = currentPage;
        this.searchList();
      },
      handleClose() {
        this.dialogVisible = false;
      },
    },
    mounted() {
      this.searchList(1);
    },
  };
</script>
